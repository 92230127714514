import React, { useEffect, useRef } from 'react';
import Matter from 'matter-js';

function App() {
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Destructure necessary modules from Matter
    const { Engine, Render, Runner, Composites, Constraint, MouseConstraint, Mouse, Composite, Bodies, Body, Vector } = Matter;

    // Create an engine
    const engine = Engine.create();

    // Setup the renderer to attach to our ref div instead of document.body
    const render = Render.create({
      element: boxRef.current!,
      engine: engine,
      options: {
        width: 800,
        height: 600,
        wireframes: false,
        showAngleIndicator: true,
        showCollisions: true,
        showVelocity: true,
      },
    });

    Render.run(render);

    // Create a runner
    const runner = Runner.create();
    Runner.run(runner, engine);

    // Add bodies
    const group = Body.nextGroup(true);
    const stack = Composites.stack(250, 255, 1, 6, 0, 0, (x: number, y: number) => {
      return Bodies.rectangle(x, y, 30, 30);
    });

    const catapult = Bodies.rectangle(400, 520, 320, 20, { collisionFilter: { group } });
    Composite.add(engine.world, [
      stack,
      catapult,
      Bodies.rectangle(400, 600, 800, 50.5, { isStatic: true }),
      // Other bodies...
    ]);

    // Add a constraint to mimic the catapult
    Composite.add(engine.world, Constraint.create({
      bodyA: catapult,
      pointB: Vector.clone(catapult.position),
      stiffness: 1,
      length: 0,
    }));

    // Add mouse control
    const mouse = Mouse.create(render.canvas);
    const mouseConstraint = MouseConstraint.create(engine, {
      mouse,
      constraint: { render: { visible: false } },
    });

    Composite.add(engine.world, mouseConstraint);

    // Keep the mouse in sync with rendering
    render.mouse = mouse;

    // Fit the render viewport to the scene
    Render.lookAt(render, {
      min: { x: 0, y: 0 },
      max: { x: 800, y: 600 },
    });

    // Cleanup function
    return () => {
      Render.stop(render);
      Runner.stop(runner);
      Composite.clear(engine.world, false);
      Render.lookAt(render, {
        min: { x: 0, y: 0 },
        max: { x: 800, y: 600 },
      });
      if (boxRef.current) {
        boxRef.current.removeChild(render.canvas);
      }
    };
  }, []);

  return <div ref={boxRef} />;
};
export default App;
